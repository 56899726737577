export const ptBr: { [key: string]: string } = {
    "notDocSent": "Nenhum documento enviado.",
    "close": "Fechar",
    "save": "Salvar",
    "page": "Página",
    "of": "de",
    "name": "Nome",
    "validate": "Validade",
    "emitter": "Emissor",
    "date": "Data",
    "signatureLocation": "Definir local de assinatura",
    "chooseYourSignature": "Escolha sua assinatura",
    "chooseSignature": "Escolha uma assinatura",
    "whatNameSignature": "* Qual o nome para sua assinatura?",
    "generateSignature": "Gerar assinatura",
    "chooseSignatureUsed": "* Escolha abaixo a assinatura que será utilizada em todo o documento:",
    "clickUsedSignature": "Clique em Ok para utilizar a assinatura escolhida:",
    "callbackMustBeFunction": "O callback deve ser uma função.",
    "thereAreUnsavedItems": "Existe itens não salvos na página, deseja sair mesmo assim?",
    "errorLoadDoc": "Ocorreu um erro ao carregar o Doc.: ",
    "signHere": "Assinar aqui",
    "rubricateHere": "Rubricar aqui",
    "subscriber": "Assinante",
    "signature": "Assinatura",
    "rubric": "Rubrica",
    "subscriptionLimitReached": "O limite total de assinaturas foi atingido.",
    "certificateLimitReached": "O limite total de certificados foi atingido.",
    "deleteMarkup": "Tem certeza que deseja excluir a marcação?",
    "deleteAllMarkup": "Tem certeza que deseja excluir toda(s) a(s) marcação(ões) da página?",
    "selectMarkup": "Por favor, selecione uma marcação.",
    "markNotFound": "Marcação não foi encontrada!",
    "incorrectPageNumber": "Número da página incorreta.",
    "requiredField": "Obrigatório",
    "invalidEmail": "E-mail inválido",
    "onlyNumbers": "Somente números",
    "minInvalid": "O valor deve ser maior que ",
    "maxInvalid": "O valor deve ser menor que ",
    "certificatesNotFound": "Nenhum certificado foi encontrado.",
    "chooseYourCertificate": "* Qual certificado irá utilizar para assinar?",
    "maxFilesNumberUpload": "O número máximo de arquivos para esse componente é ",
    "theFile": "O arquivo ",
    "hasZeroSize": " possui tamanho zero",
    "theFileSize": "O tamanho do arquivo ",
    "maxFilesSizeUpload": " é maior que o permitido. Tamanho máximo permitido ",
    "filesTypeUpload": " possui um tipo não permitido. Tipos de arquivos permitidos: ",
    "form": "Formulário",
    "execute": "Executar",
    "formSuccessRegister": "Formulário cadastrado com sucesso!",
    "formSuccessUpdated": "Formulário atualizado com sucesso!",
    "flowSuccessStarted": "Fluxo iniciado com sucesso!",
    "flowSuccessUpdated": "Fluxo atualizado com sucesso!",
    "taskSuccessExecuted": "Tarefa executada com sucesso!",
    "failedLoadMap": "Falha ao carregar o mapa.",
    "chooseFile": "Escolher arquivo(s)",
    "uploadFileTitle": "Enviar arquivo(s)",
    "dropTheFileHere": "Solte o(s) documento(s) aqui, ou clique para escolher",
    "optSelect": "Selecione...",
    "genericError": "Algo de errado ocorreu ao tentar realizar a sua solicitação. Por favor tente novamente em instantes!",
    "errorExternalWebService": "Algo de errado ocorreu ao tentar realizar a chamada do serviço externo. Por favor tente novamente em instantes!",
    "showing": "Exibindo",
    "to": "até",
    "records": "registros",
    "noItemsFounds": "Nenhum item foi localizado.",
    "first": "Primeiro",
    "last": "Último",
    "signatureAlreadyChecked": "Uma ou mais assinaturas marcadas",
    "rubricAlreadyChecked": "Uma ou mais rubricas marcadas",
    "signatureNotChecked": "Nenhuma assinatura marcada",
    "rubricNotChecked": "Nenhuma rubrica marcada",
    "chooseYourRubric": "Escolha sua rubrica",
    "whatNameRubric": "* Qual o nome para sua rubrica?",
    "generateRubric": "Gerar Rubrica",
    "chooseRubric": "* Escolha abaixo a rubrica que será utilizada em todo o documento:",
    "clickUsedRubric": "Clique em Ok para utilizar a rubrica escolhida:",
    "previousAppointment": "Ir para a assinatura/rubrica anterior.",
    "next": "Próxima",
    "previous": "Anterior",
    "nextAppointment": "Ir para a assinatura/rubrica seguinte.",
    "errorFormTypeProcess": "O Formulário é do tipo processo e só pode ser utilizado na função novo fluxo.",
    "errorFormTypeDoc": "O Formulário é do tipo documento e só pode ser utilizado na função novo documento.",
    "selectOneMarkupType": "Selecione uma das opções: Assinatura e/ou Rubrica!",
    "wereCompleted": "Concluída(s)",
    "signatures": "assinatura(s)"
}