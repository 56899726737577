export const enUS: { [key: string]: string } = {
    "notDocSent": "No documents sent.",
    "close": "Close",
    "save": "Save",
    "page": "Page",
    "of": "of",
    "name": "Name",
    "validate": "Validate",
    "emitter": "Emitter",
    "date": "Date",
    "signatureLocation": "Set subscription location",
    "chooseYourSignature": "Choose your signature",
    "chooseSignature": "Choose a signature",
    "whatNameSignature": "* What is your subscription name?",
    "generateSignature": "Generate signature",
    "chooseSignatureUsed": "* Choose below the signature that will be used throughout the document:",
    "clickUsedSignature": "Click Ok to use your chosen signature:",
    "callbackMustBeFunction": "Callback must be a function.",
    "thereAreUnsavedItems": "There are unsaved items on the page, would you like to leave anyway?",
    "errorLoadDoc": "There was an error loading Doc.: ",
    "signHere": "Sign here",
    "rubricateHere": "Rubricate here",
    "subscriber": "Subscriber",
    "signature": "Signature",
    "rubric": "Rubric",
    "subscriptionLimitReached": "Total subscription limit reached.",
    "certificateLimitReached": "Total certificate limit reached.",
    "deleteMarkup": "Are you sure you want to delete the markup?",
    "deleteAllMarkup": "Are you sure you want to delete all markups from the page?",
    "selectMarkup": "Please select a markup.",
    "markNotFound": "Markup not found!",
    "incorrectPageNumber": "Incorrect page number.",
    "requiredField": "Required",
    "invalidEmail": "Invalid e-mail",
    "onlyNumbers": "Only numbers",
    "minInvalid": "The value must be greater than ",
    "maxInvalid": "The value must be less than ",
    "certificatesNotFound": "No certificate was found.",
    "chooseYourCertificate": "* Which certificate will you use to sign?",
    "maxFilesNumberUpload": "The maximum number of files for this component is ",
    "theFile": "The file ",
    "hasZeroSize": " has zero size",
    "theFileSize": "The file size ",
    "maxFilesSizeUpload": " is larger than allowed. Maximum allowed size ",
    "filesTypeUpload": " has an unsupported type. Allowed file types: ",
    "form": "Form",
    "execute": "Execute",
    "formSuccessRegister": "Form successfully registered!",
    "formSuccessUpdated": "Form successfully updated!",
    "flowSuccessStarted": "Flow started successfully!",
    "flowSuccessUpdated": "Flow updated successfully!",
    "taskSuccessExecuted": "Task successfully executed!",
    "failedLoadMap": "Failed to load the map.",
    "chooseFile": "Choose file(s)",
    "uploadFileTitle": "Send file(s)",
    "dropTheFileHere": "Drop the document(s) here, or click to choose",
    "optSelect": "Select...",
    "genericError": "Something went wrong while trying to make your request. Please try again in a moment!",
    "errorExternalWebService": "Something went wrong when trying to call the external service. Please try again in a moment!",
    "showing": "Showing",
    "to": "to",
    "records": "records",
    "noItemsFounds": "No items were found.",
    "first": "First",
    "last": "Last",
    "signatureAlreadyChecked": "One or more signature already checked",
    "rubricAlreadyChecked": "Uma ou mais rubricas marcadas",
    "signatureNotChecked": "Any signature checked",
    "rubricNotChecked": "Any rubric checked",
    "chooseYourRubric": "Choose your rubric",
    "whatNameRubric": "* What is your rubric name?",
    "generateRubric": "Generate Rubric",
    "chooseRubric": "* Choose below the rubric that will be used throughout the document:",
    "clickUsedRubric": "Click Ok to use your chosen rubric:",
    "previousAppointment": "Go to previus subscription/rubric.",
    "next": "Next",
    "previous": "Previous",
    "nextAppointment": "Go to next subscription/rubric.",
    "errorFormTypeProcess": "The Form is of the process type and must be used to start a new flow.",
    "errorFormTypeDoc": "The Form is of the documento type and must be used to create a new document.",
    "selectOneMarkupType": "Select as options: subscription and/or rubric!",
    "wereCompleted": "Were completed",
    "signatures": "signature(s)"
}