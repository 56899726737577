import { Translate } from '../../services/translate-service';
import { IFormValidatorOptions } from './form-validator.model';
import Inputmask from "inputmask";
import * as toastr from 'toastr';

export class FormValidator {

    /**
     * Opções para o form validator
     *
     * @type {IFormValidatorOptions}
     * @memberof FormValidator
     */
    public options: IFormValidatorOptions;

    /**
     * Translate i18n service
     *
     * @private
     * @type {Translate}
     * @memberof FormValidator
     */
    private objTranslate: Translate;

    /**
     * Variável para validação do formulário
     *
     * @private
     * @memberof FormValidator
     */
    private retorno: number = 1;

    /**
     * Container do formulário
     *
     * @readonly
     * @type {string}
     * @memberof FormValidator
     */
    public get containerId(): string {
        return this.options.containerId;
    }

    /**
     * Botão que será bloqueado até a validação acontecer
     *
     * @readonly
     * @type {string}
     * @memberof FormValidator
     */
    public get buttonId(): string {
        return this.options.buttonId && this.options.buttonId.indexOf('.') != -1 ? this.options.buttonId : `#${this.options.buttonId}`;
    }

    public isDirty: boolean;

    constructor(options: IFormValidatorOptions) {
        this.options = options;
        this.initTranslate(options.culture);
        this.initToastr();
        this.initValidate();
        this.restrictInputs();
    }

    /**
     * Inicializa o serviço de tradução
     *
     * @private
     * @param {string} lang
     * @memberof FormValidator
     */
    private initTranslate(lang: string): void {
        this.objTranslate = Translate.getInstance();
        this.objTranslate.Lang = lang;
    }

    /**
     * Realiza a tradução
     *
     * @private
     * @param {string} key Chave para tradução
     * @returns {string} Texto traduzido
     * @memberof FormValidator
     */
    private translate(key: string): string {
        return this.objTranslate.translate(key);
    }
    /**
     * Initialize toast options
     *
     * @private
     * @memberof FormValidator
     */
    private initToastr(): void {
        toastr.options.closeButton = true;
        toastr.options.debug = false;
        toastr.options.newestOnTop = false;
        toastr.options.progressBar = true;
        toastr.options.positionClass = "toast-top-right";
        toastr.options.preventDuplicates = true;
        toastr.options.onclick = null;
        toastr.options.showDuration = 300;
        toastr.options.hideDuration = 1000;
        toastr.options.timeOut = 5000;
        toastr.options.extendedTimeOut = 1000;
        toastr.options.showEasing = "swing";
        toastr.options.hideEasing = "linear";
    }

    /**
     * Inicializa a validação do formulário com métodos de keyup e click para os inputs
     *
     * @private
     * @memberof FormValidator
     */
    private initValidate(): void {
        let that = this;
        $(that.buttonId).prop("disabled", true);
        this.unmaskAllInputs();
        const datePickerOptions: any = {
            language: this.options.culture || 'pt-BR',
            todayHighlight: true
            /*format: {

                 * Say our UI should display a week ahead,
                 * but textbox should store the actual date.
                 * This is useful if we need UI to select local dates,
                 * but store in UTC

                toDisplay: function (date, format, language) {
                    var d = new Date(date);
                    d.setDate(d.getDate() - 7);
                    return d.toISOString();
                },
                toValue: function (date, format, language) {
                    var d = new Date(date);
                    d.setDate(d.getDate() + 7);
                    return new Date(d);
                }
            }*/
        }

        $('.input-daterange').datepicker(datePickerOptions);
        $('.valida-date input').datepicker({ ...datePickerOptions, autoclose: true })
            .on('hide', function (e) {
                $(e.target).trigger('blur');
            });;
        $('.calendar-datepicker').datepicker(datePickerOptions);

        const selectorNumber = document.querySelectorAll('#' + this.containerId + ' .valida-numeric input');
        if (selectorNumber && selectorNumber.length > 0) {
            //integer mask
            if (!(selectorNumber as any).inputmask) {
                Inputmask({ regex: "\\d*" }).mask(selectorNumber);
            }
        }

        const selectorDecimal = document.querySelectorAll('#' + this.containerId + ' .valida-decimal input');
        if (selectorDecimal && selectorDecimal.length > 0) {
            //decimal mask
            let groupSeparator = '.';
            let radixPoint = ','
            if (this.objTranslate.Lang === 'en-US') {
                groupSeparator = ',';
                radixPoint = '.';
            }

            const maskOpts = {
                allowPlus: false,
                allowMinus: true,
                rightAlign: false,
                groupSeparator: groupSeparator,
                numericInput: true,
                radixPoint: radixPoint,
                digits: 2,
                placeholder: '0',
                showMaskOnFocus: true,
                showMaskOnHover: false,
                unmaskAsNumber: true
            }
            if (!(selectorDecimal as any).inputmask) {
                Inputmask('decimal', maskOpts).mask(selectorDecimal);
            }
        }

        const selectorTime = document.querySelectorAll('#' + this.containerId + ' .valida-time input');
        if (selectorTime && selectorTime.length > 0) {
            const maskOptsTime = {
                regex: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$',
                showMaskOnHover: false,
                showMaskOnFocus: true,
                insertMode: false,
                skipOptionalPartCharacter: ' ',
                clearIncomplete: true,
                autoclear: false,
                autoUnmask: false,
                onincomplete: function() {
                    $(selectorTime).trigger('blur');
                },
                oncomplete: function () {
                    $(selectorTime).trigger('blur');
                }
            }
            if (!(selectorTime as any).inputmask) {
                Inputmask(maskOptsTime).mask(selectorTime);
            }
           
        }

        $(selectorNumber).on('change', (e) => {
            e.target.dispatchEvent(new CustomEvent('changeDate', { bubbles: true }));
        });

        $(selectorTime).on('change', (e) => {
            e.target.dispatchEvent(new CustomEvent('changeDate', { bubbles: true }));
        });
        
        $(selectorDecimal).on('change', (e) => {
            e.target.dispatchEvent(new CustomEvent('changeDate', { bubbles: true }));
        });
        
        $('.input-daterange, .valida-date input, .calendar-datepicker').on('change', (e) => {
            e.target.dispatchEvent(new CustomEvent('changeDate', { bubbles: true }));
        });

        $("#" + this.containerId).find("textarea").on("keyup blur", function (event) {
            var code: any = event.key || event.keyCode || event.which;
            if (code != '9') {
                that.validate($(this));
            }
        });

        $("#" + this.containerId).find("input").not('input[type="number"]').not('input[type="checkbox"]').not('input[type="radio"]').on("keyup blur", function (event) {
            var code: any = event.key || event.keyCode || event.which;
            if (code != '9') {
                that.validate($(this));
            }
        });
        $("#" + this.containerId).find("select").on("change blur", function (event) {
            var code: any = event.key || event.keyCode || event.which;
            if (code != '9') {
                that.validate($(this));
            }
        });

        $("#" + this.containerId).find("input:checkbox").on('click', function (event) {
            that.validate($(this));
        });
        $("#" + this.containerId).find("input:radio").on('click', function (event) {
            that.validate($(this));
        });
        
        $("#" + this.containerId).find("input[type='file']").on('click', function (event) {
            that.validate($(this));
        });
        $("#" + this.containerId).find("input[type='file']").on('change', function (event) {
            that.validate($(this));
        });

        that.validate(null);
    }

    /**
     * Método de validação dos inputs parametro target com o objeto do formulário
     *
     * @private
     * @param {*} target
     * @memberof FormValidator
     */
    private validate(target: any): void {
        let that = this;

        $("#" + this.containerId).find(":input").not("button").each(
            function () {
                let msgError = that.translate("requiredField");
                
                that.retorno = 1;
                if ($(this).closest(".input-wrap:visible").hasClass("valida-email")) {
                    if (!that.checkMail($(this).val())) {
                        that.retorno = 0;
                        msgError = that.translate("invalidEmail")
                    }
                }
                if ($(this).closest(".input-wrap:visible").hasClass("valida-radio") && $(this).closest(".input-wrap:visible").hasClass("obrigatorio")) {
                    var Radio = document.getElementsByName($(this).attr("name"));
                    var marcado = 0;

                    if (Radio.length > 0) {
                        for (var i = 0; i < Radio.length; i++) {
                            if ((<any>Radio[i]).checked) {
                                marcado += 1;
                            }
                        }
                    } else {
                        if ((<any>Radio).checked) {
                            marcado += 1;
                        }
                    }

                    if (marcado == 0) {
                        that.retorno = 0;
                    }
                }

                var blnIsCheckbox = false;
                if ($(this).closest(".input-wrap:visible").hasClass("valida-checkbox") && $(this).closest(".input-wrap:visible").hasClass("obrigatorio")) {
                    blnIsCheckbox = true;
                    var marked = $(this).closest('.input-content.input-content--checkbox').find('input:checkbox:checked').length;
                    if (!marked || marked === 0)
                        that.retorno = 0;
                    else
                        that.retorno = 1;
                }

                if ($(this).closest(".input-wrap:visible").not('.valida-checkbox').hasClass("obrigatorio")) {
                    if (!$(this).val() || $(this).val() == "" || $(this).val() == $(this).attr("placeholder")) {
                        that.retorno = 0;
                    }
                }

                const typeInput = $(this).attr("type");

                if (that.retorno === 0) {
                    $(this).addClass('invalid');
                    if ($(this)[0] === $(target)[0]) {
                        $(target).closest(".input-wrap").find(".erroTxt").show().text(msgError);
                        $(target).addClass("inputErro");
                        $(target).next('span').addClass("inputErro");

                        if(typeInput == 'file'){
                            $(target).closest(".input-wrap").find(".input--file").addClass("inputErro");

                        }

                        if (blnIsCheckbox) {
                            var requiredCheckbox = that.translate("requiredField");
                            $(target).closest(".input-wrap").find(".erroTxt").show().text(requiredCheckbox);
                            $(target).closest('.input-content.input-content--checkbox').find('span.checkbox').addClass('inputErro');
                            $(target).closest('.input-content.input-content--checkbox').find('input:checkbox').addClass('inputErro');
                        }
                    }
                } else {
                    $(this).removeClass('invalid');
                    if ($(this)[0] === $(target)[0]) {
                        if (blnIsCheckbox) {
                            $(target).closest('.input-content.input-content--checkbox').find('span.checkbox.inputErro').removeClass('inputErro');
                            $(target).closest('.input-content.input-content--checkbox').find('input:checkbox').removeClass('invalid').removeClass('inputErro');
                        }
                        
                        if(typeInput == 'file'){
                            $(target).closest(".input-wrap").find(".input--file").removeClass("inputErro");
                        }

                        $(target).closest(".input-wrap").find(".erroTxt").hide();
                        $(target).removeClass("inputErro");
                        $(target).next('span').removeClass("inputErro");
                    }

                    if ($(this).is(':disabled')) {
                        $(this).closest(".input-wrap").find(".erroTxt").hide();
                        $(this).removeClass("inputErro");
                        $(this).next('span').removeClass("inputErro");
                    }
                }
            }
        );
        that.enabledButton()
    }

    private enabledButton() {
        this.isDirty = $("#" + this.containerId + " .input-wrap:visible .inputErro:enabled").length > 0 || $("#" + this.containerId + " .input-wrap:visible .invalid:enabled").length > 0;
        if (this.buttonId) {
            $(this.buttonId).prop("disabled", this.isDirty);
        }
    }


    /**
     * Restringe digitação nos inputs de caracteres inválidos pelo tipo
     *
     * @private
     * @memberof FormValidator
     */
    private restrictInputs(): void {
        let that = this;
        $("#" + this.containerId + " .valida-numero input").on("keydown", function (event) {
            $(this).closest('.input-wrap').find(".erroTxt").text("");
            $(this).removeClass("inputErro");
            return that.validaNumero(event);
        });

        $("#" + this.containerId + " .valida-numero input").on("keyup", function (event) {
            var code: any = event.key || event.keyCode || event.which;
            if (code != '9') {
                $(this).closest('.input-wrap').find(".erroTxt").text("");
                $(this).removeClass("inputErro");
                return that.validaMinMax(event);
            }
        });

        $("#" + this.containerId + " .valida-numero input").on("blur", function (event) {
            $(this).closest('.input-wrap').find(".erroTxt").text("");
            $(this).removeClass("inputErro");
            return that.validaMinMax(event);
        });

    }

    /**
     * Valida quando somente número é aceito no input
     *
     * @private
     * @param {*} event
     * @returns {boolean}
     * @memberof FormValidator
     */
    private validaNumero(event: any): boolean {
        const charCode = (event.which !== null && event.which !== undefined) ? event.which : event.keyCode;
        if (charCode === 69 || charCode === 190 || charCode === 188) { // Letter e, dot and comma
            event.preventDefault();
            return false;
        }

        return true;
    }

    /**
     * Valida Minimo e Máximo nos inputs numericos
     *
     * @private
     * @param {*} event
     * @returns {boolean}
     * @memberof FormValidator
     */
    private validaMinMax(event: any): boolean {
        const value = parseFloat(event.target.value);
        const jObj = $(event.target);

        if (isNaN(value)) {
            event.preventDefault();
            jObj.val('');
            jObj.closest('.input-wrap').find(".erroTxt").show().text(this.translate("requiredField"));
            jObj.addClass("inputErro");
            return false;
        }

        if (value !== 0 && (isNaN(value))) {
            event.preventDefault();
            return false;
        }

        const min = parseFloat(event.target.min) || null;
        const max = parseFloat(event.target.max) || null;

        if (value !== null && min !== null && value < min) {
            jObj.val(min);
        }

        if (value !== null && max !== null && value > max) {
            if ((value.toString().length) > max.toString().length) {
                let regex = value.toString().match(/\d+/g);
                let newValue = regex.join('');
                jObj.val(newValue.substring(0, max.toString().length));
            }
            else {
                jObj.val(max);
            }
        }

        return true;
    }

    /**
     * Verifica a validação do e-mail digitado no input
     *
     * @private
     * @param {*} mail
     * @returns {boolean}
     * @memberof FormValidator
     */
    private checkMail(mail): boolean {
        var er = new RegExp(
            /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
        if (typeof (mail) == "string") {
            if (er.test(mail)) {
                return true;
            }
        } else if (typeof (mail) == "object") {
            if (er.test(mail.value)) {
                return true;
            }
        } else {
            return false;
        }
    }

    public unmaskInput(obj: any): void {
        // var test = document.querySelectorAll('#' + this.containerId + ' input');
        if (obj[0] && (obj[0]as any).inputmask) {
            Inputmask.remove(obj[0]);
            $(obj[0]).val('');
        }
        if ($(obj[0]).data("datepicker") != null) {
            $(obj[0]).datepicker("destroy");
            $(obj[0]).val('');
        }
    }

    public unmaskAllInputs(): void {
        var test = document.querySelectorAll('#' + this.containerId + ' input');

        for (var i = 0; i < test.length; i++) {
            if (Inputmask(test[i])) {
                Inputmask.remove(test[i]);
            }
            if ($(test[i]).data("datepicker") != null) {
                $(test[i]).datepicker("destroy");
            }
        }
    }

    public resetInput(objSelctor: any): void {
        $(objSelctor).closest('.input-wrap').find(".erroTxt").text("");
        $(objSelctor).removeClass("inputErro");
    }

    public resetInputAll () {
        this.resetInput(`#${this.containerId} :input`);
        this.resetInput(`#${this.containerId} select`);
    }

    public getToastrWarning(msg: string): any {
        return toastr.warning(msg);
    }

    public getToastrSuccess(msg: string): any {
        return toastr.success(msg);
    }

    public getToastrError(msg: string): any {
        return toastr.error(msg);
    }

}